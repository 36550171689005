import { useDispatch } from 'react-redux';
import { $actions } from 'Store/ducks';
import { useAsyncAction } from 'Hooks/Common';
import { SignUpWithConfirmCodeTemporary } from 'Interfaces/Auth';

export default () => {
  const dispatch = useDispatch();

  return useAsyncAction((payload: SignUpWithConfirmCodeTemporary) =>
    $actions.user.confirmTemporary(dispatch, payload),
  );
};
