import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { rootActions } from 'Store';

interface ClearShowTrialSuccessModal {
  (): void;
}

export default () => {
  const dispatch = useDispatch();

  const clearShowTrialSuccessModal: ClearShowTrialSuccessModal = useCallback(() => {
    dispatch(rootActions.user.clearShowTrialSuccessModal());
  }, [dispatch]);

  return [clearShowTrialSuccessModal] as const;
};
