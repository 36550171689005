import sagas from './sagas';
import {
  signIn,
  signInPrimary,
  $signInPrimary,
  signInTwillio,
  $signInTwillio,
  $signInGoogleAuthenticator,
  signInGoogleAuthenticator,
  signUp,
  logout,
  connectSocket,
  disconnectSocket,
  setUnauthorized,
  setTwoFactor,
  disableTwillio2fa,
  $disableTwillio2fa,
  $confirmEmail,
  $confirmEmailByTwilio,
  $signUp,
  $sendConformationEmail,
  getSignedGetUrl,
  getSignedGetUrlHash,
  $getSignedGetUrl,
  getCompatibleSignedGetUrl,
  $getCompatibleSignedGetUrl,
  getSignedPutUrl,
  $getSignedPutUrl,
  deleteAccount,
  $deleteAccount,
  updateProfileInfo,
  $updateProfileInfo,
  getSignedGetDownloadUrl,
  $getSignedGetDownloadUrl,
  getSignersAvatars,
  $getSignersAvatars,
  updateCompanyInfo,
  $updateCompanyInfo,
  enableGoogleAuthenticator,
  disableGoogleAuthenticator,
  verifyGoogleCode,
  $enableGoogleAuthenticator,
  $disableGoogleAuthenticator,
  $verifyGoogleCode,
  verifyPhone,
  $verifyPhone,
  generateCode,
  $generateCode,
  putRequisite,
  $putRequisite,
  getCurrentUser,
  $getCurrentUser,
  putCompanyLogo,
  $putCompanyLogo,
  joinSocketRoom,
  sendPasswordChangeEmail,
  $sendPasswordChangeEmail,
  changePassword,
  $changePassword,
  setPasswordToken,
  confirmEmail,
  confirmEmailByTwilio,
  setEmailToken,
  clearEmailToken,
  sendConformationEmail,
  clearPasswordToken,
  getSignedGetUrlBulk,
  $getSignedGetUrlBulk,
  subscribeOnAPIUpdates,
  getSignedPutAssetUrl,
  $getSignedPutAssetUrl,
  $subscribeOnAPIUpdates,
  setEmailConfirmationData,
  unsubscribeFromAPIUpdates,
  $unsubscribeFromAPIUpdates,
  signInAppSumoUser,
  $signInAppSumoUser,
  applyAppSumoLink,
  $putAvatar,
  $signUpWithPlanPrepare,
  $signUpWithConfirmCode,
  $updateEmail,
  updateEmail,
  setIsEmailConfirmed,
  initAccessToken,
  finishInitAccessToken,
  updateGoogleClientId,
  $updateGoogleClientId,
  $signOut,
  getEmbedSignedUrlBulk,
  $getEmbedSignedUrlBulk,
  $getSignedGetUrlHash,
  clearIntegrationData,
  ltdSignUp,
  $ltdSignUp,
  signUpTemporary,
  $signUpTemporary,
  signUpFromTemporary,
  $signUpFromTemporary,
  confirmTemporary,
  $confirmTemporary,
  clearShowTrialSuccessModal,
} from './actionCreators';
import reducer from './reducer';

export default {
  sagas,
  reducer,
  actions: {
    signIn,
    setTwoFactor,
    enableGoogleAuthenticator,
    disableGoogleAuthenticator,
    verifyGoogleCode,
    signInPrimary,
    signInTwillio,
    signInGoogleAuthenticator,
    signUp,
    setEmailConfirmationData,
    logout,
    verifyPhone,
    generateCode,
    getSignedGetUrl,
    getCompatibleSignedGetUrl,
    getSignedPutUrl,
    getSignedGetDownloadUrl,
    updateCompanyInfo,
    putCompanyLogo,
    putRequisite,
    deleteAccount,
    getCurrentUser,
    disableTwillio2fa,
    updateProfileInfo,
    getSignedPutAssetUrl,
    connectSocket,
    disconnectSocket,
    setUnauthorized,
    joinSocketRoom,
    sendPasswordChangeEmail,
    changePassword,
    setPasswordToken,
    clearPasswordToken,
    getSignedGetUrlBulk,
    subscribeOnAPIUpdates,
    confirmEmail,
    confirmEmailByTwilio,
    sendConformationEmail,
    unsubscribeFromAPIUpdates,
    setEmailToken,
    clearEmailToken,
    signInAppSumoUser,
    applyAppSumoLink,
    getSignersAvatars,
    updateEmail,
    setIsEmailConfirmed,
    initAccessToken,
    finishInitAccessToken,
    updateGoogleClientId,
    getEmbedSignedUrlBulk,
    clearIntegrationData,
    ltdSignUp,
    signUpTemporary,
    signUpFromTemporary,
    confirmTemporary,
    clearShowTrialSuccessModal,
  },
};

export const $actions = {
  signInPrimary: $signInPrimary,
  signInTwillio: $signInTwillio,
  signInGoogleAuthenticator: $signInGoogleAuthenticator,
  updateEmail: $updateEmail,
  signUp: $signUp,
  signUpTemporary: $signUpTemporary,
  signUpFromTemporary: $signUpFromTemporary,
  confirmTemporary: $confirmTemporary,
  ltdSignUp: $ltdSignUp,
  getSignersAvatars: $getSignersAvatars,
  changePassword: $changePassword,
  confirmEmail: $confirmEmail,
  confirmEmailByTwilio: $confirmEmailByTwilio,
  sendConformationEmail: $sendConformationEmail,
  getSignedGetUrl: $getSignedGetUrl,
  getCompatibleSignedGetUrl: $getCompatibleSignedGetUrl,
  getSignedGetUrlHash: $getSignedGetUrlHash,
  getSignedPutAssetUrl: $getSignedPutAssetUrl,
  verifyPhone: $verifyPhone,
  enableGoogleAuthenticator: $enableGoogleAuthenticator,
  disableGoogleAuthenticator: $disableGoogleAuthenticator,
  verifyGoogleCode: $verifyGoogleCode,
  generateCode: $generateCode,
  putCompanyLogo: $putCompanyLogo,
  putAvatar: $putAvatar,
  disableTwillio2fa: $disableTwillio2fa,
  updateCompanyInfo: $updateCompanyInfo,
  getSignedGetDownloadUrl: $getSignedGetDownloadUrl,
  deleteAccount: $deleteAccount,
  updateProfileInfo: $updateProfileInfo,
  getSignedPutUrl: $getSignedPutUrl,
  putRequisite: $putRequisite,
  signOut: $signOut,
  getCurrentUser: $getCurrentUser,
  sendPasswordChangeEmail: $sendPasswordChangeEmail,
  getSignedGetUrlBulk: $getSignedGetUrlBulk,
  subscribeOnAPIUpdates: $subscribeOnAPIUpdates,
  unsubscribeFromAPIUpdates: $unsubscribeFromAPIUpdates,
  signInAppSumoUser: $signInAppSumoUser,
  signUpWithPlanPrepare: $signUpWithPlanPrepare,
  signUpWithConfirmCode: $signUpWithConfirmCode,
  updateGoolgeClientId: $updateGoogleClientId,
  getEmbedSignedUrlBulk: $getEmbedSignedUrlBulk,
};
