import React from 'react';
import UIButton from 'Components/UIComponents/UIButton';
import UIModal from 'Components/UIComponents/UIModal';
import useIsMobile from 'Hooks/Common/useIsMobile';
import classNames from 'classnames';
import { discountPlanPrices, PlanDurations, PlanTypes } from 'Interfaces/Billing';
import dayjs from 'dayjs';
import { formatDateToStringForTrialInfo } from 'Utils/formatters';

export interface TrialSuccessModalProps {
  onSubmit: () => void;
  onClose: () => void;
}

const TrialSuccessModal = ({ onSubmit, onClose }: TrialSuccessModalProps) => {
  const isMobile = useIsMobile();

  const price = discountPlanPrices[PlanTypes.BUSINESS][PlanDurations.MONTHLY];
  const nextBillingDate = dayjs(new Date())
    .add(7, 'day')
    .format('MMMM D, YYYY');

  return (
    <UIModal
      onClose={onClose}
      hideCloseIcon={true}
      className={classNames('trialSuccessModal__inner', { mobile: isMobile })}
    >
      <div className={classNames('trialSuccessModal__wrapper')}>
        <p className="trialSuccessModal__header">
          Thank you for signing up for our 7-day free trial!
        </p>
        <p className="trialSuccessModal__text">
          We&apos;re excited to have you onboard. As a reminder, your trial will
          automatically transition to a full subscription for <b>${price}/month</b> on{' '}
          <b>{formatDateToStringForTrialInfo(nextBillingDate)}</b>. If you&apos;d like to
          continue enjoying our service without interruption, no action is needed. If you
          have any questions or would like to learn more about your subscription,
          we&apos;re here to help.
        </p>
        <UIButton
          priority="primary"
          title={'Learn More'}
          handleClick={onSubmit}
          className="trialSuccessModal__button--download"
        />
        <div className="trialSuccessModal__button--cancel" onClick={onClose}>
          Continue
        </div>
      </div>
    </UIModal>
  );
};

export default TrialSuccessModal;
